import React from "react"
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LineShareButton,
    LineIcon,
} from 'react-share';

const Share = ({ url, title }) => {
    return (
        <div className="share-list my-2 text-center">
            <FacebookShareButton url={url} title={title} className="share-list-item">
                <FacebookIcon size={40} round />
            </FacebookShareButton>
            <TwitterShareButton url={url} title={title} via="yukyu30" className="share-list-item">
                <TwitterIcon size={40} round />
            </TwitterShareButton>
            <LineShareButton url={url} title={title} className="share-list-item">
                <LineIcon size={40} round />
            </LineShareButton>
        </div>

    )
}

export default Share